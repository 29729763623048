%title-font {
	font-family: 'Montserrat', sans-serif;
	letter-spacing:0.1rem;
	font-weight:300;
}


/*
@mixin square($width, $height, $color) {
	display:block;
	content:'';
	border:$default-border-width solid $color;
	width:$width;
	height:$height;
	position:absolute;
	pointer-events:none;
}
*/
/*
@mixin tache($color, $scale:1) {
	display:block;
	content:'';
	background-repeat:no-repeat;
	background-position:center center;
	background-size:contain;
	height:0;
	padding:0;
	pointer-events:none;
	width:(100% * $scale);
	
	@if $color == rose {
		background-image:url(../images/tache-rose.svg);
		padding-bottom:(53% * $scale);
	}
	@else if $color == vert {
		background-image:url(../images/tache-vert.svg);
		padding-bottom:(74% * $scale);
	}
	@else if $color == bleu {
		background-image:url(../images/tache-bleu.svg);
		padding-bottom:(52% * $scale);
	}
	@else if $color == bleu-eau {
		background-image:url(../images/tache-bleu-eau.svg);
		padding-bottom:(52% * $scale);
	}

	position:absolute;
}*/


@mixin flower($color, $scale:1) {
	display:block;
	content:'';
	background-repeat:no-repeat;
	background-position:center center;
	background-size:contain;
	height:0;
	padding:0;
	pointer-events:none;
	width:(100% * $scale);
	
	@if $color == rose {
		background-image:url(../images/fleur-rose.svg);
		padding-bottom:(119% * $scale);
	}
	@else if $color == vert {
		background-image:url(../images/fleur-vert.svg);
		padding-bottom:(113% * $scale);
	}
	@else if $color == bleu {
		background-image:url(../images/fleur-bleu.svg);
		padding-bottom:(101% * $scale);
	}
	@else if $color == bleu-eau {
		background-image:url(../images/fleur-bleu-eau.svg);
		padding-bottom:(101% * $scale);
	}
	@else if $color == blanche {
		background-image:url(../images/fleur-blanche.svg);
		padding-bottom:(101% * $scale);
	}

	position:absolute;
}


// Helper mixins
// generic transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}
// rotate
@mixin rotate ($deg) {
	@include transform(rotate(#{$deg}deg));
}
 
// scale
@mixin scale($scale) {
	@include transform(scale($scale));
} 

// translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin ($origin) {
	moz-transform-origin: $origin;
	-o-transform-origin: $origin;
	-ms-transform-origin: $origin;
	-webkit-transform-origin: $origin;
	transform-origin: $origin;
}