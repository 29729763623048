.sections {

	.section:first-child {	}
}


// ==========================================================
// Section individuelle standard

.section {
	position:relative;
	padding:5%;
	margin-bottom:40px;
	z-index:10;

	.section__title {
		position:relative;
		text-transform:uppercase;
		z-index:30;

		.section__title-caption { 
			position:relative;
			z-index:10;
		}

		// Trait sous le titre
		&:after {
			position:absolute;
			content:"";
			width:50px;
			height:$default-border-width;
			left:0;
			bottom:-20px;
		}
	}

	.section__content {
		position:relative;
		z-index:20;
	}

	// Carré en overlay
	.section__square {
		z-index:0;
	}
}

// ============================
// Styles spécifiques
// ============================

// Blancs
.section:nth-child(odd) {

	.section__title {
		display:inline-block;
		position:relative;
		//left:-15%;
		//margin-top:-10%;
		text-align:left;
		
		// Trait sous le titre
		&:after {
			background:$batoul-bleu-clair;
		}
	}

	// Coin
	&:after {
		display:block;
		content:'';
		background:url(../images/crochet-droit.svg) no-repeat center center;
		background-size:contain;
		position:absolute;
		right:0;
		bottom:0;
		width:61px;
		height:61px;
	}
}

// Roses
.section:nth-child(even) {
	background:tint($batoul-rose,20%);

	.section__title {
		text-align:left;
		width:80%;

		// Trait sous le titre
		&:after {
			background:#ffffff;
		}
	}
}



// Large screen

@media (min-width: 1024px) {
	.sections {
		.section:first-child {
			margin-top:-10%;
		}
	}

	.section {
		width:60%;
	}

	// Blancs
	.section:nth-child(odd) {
		background:rgba(#ffffff,0.8);
		left:40%;
		box-shadow:-5px -5px 15px rgba($batoul-bleu-nuit, 0.07);
	}

	// Roses
	.section:nth-child(even) {
		.section__title {
			margin-left:auto;
			margin-top:0;
		}
	}
}