header.banner {
	position:relative;
	text-align:center;
}

.main-logo {
	position:relative;
	img {
		width:100%;
		height:auto;
		max-width: 295px;
	}
}

.image-heading {
	position:relative;


	&__image {
		display:block;
		max-width:100%;
		height:auto;
	}

	&__overlay-content {
		@extend %title-font;
		font-size:1.8rem;
		text-transform:uppercase;
		margin:25px 0;

		p:last-child { margin-bottom:0; } // Avoid extra margins on <p>
	}
}

.image-heading--home {
	.image-heading__image {
		max-width:100%; 
	}

	/*&:before {
		@include square(140px, 35%, $batoul-rose);
		right:4%;
		top:45%;
		z-index:0;
		margin-top:-17.5%;
	}*/

	&:after {
		@include flower(blanche,0.3);
		left:0;
		bottom:-5%;
		z-index:0;
		opacity:0.6;
		mix-blend-mode: overlay;
	}

	&:before, &:after { display:none; } // only display it on large screen
	.image-heading__overlay-content {
		font-size:1.2em;
		text-align:center;
		width:100%;
	}
}

.navbar-batoul {
	margin:0;
	font-size:0.75rem;
	//min-height:3.5rem;

	.navbar-brand,
	.navbar-toggler {
		color: red;

		&:hover, &:focus {
			color:green;
		}
	}

	// <ul>
	.navbar-nav {
		@extend %title-font;
		width:100%;
		text-transform:uppercase;
		text-align:center;
	}

	.nav-link {
		display:inline-block;
		position:relative;
		color:$batoul-bleu-nuit;
		padding-right: .5rem;
   		padding-left: .5rem;

		&:after {
			content:'';
			display:inline-block;
			height:$default-border-width;
			background:$batoul-bleu-canard;
			position:absolute;
			bottom:0;
			left:0.5rem;
			right:0.5rem;
			opacity:0;
		}

		&:hover:after {
			
		}
	}

	.active > .nav-link {
		font-weight:500;
	}

	.active > .nav-link:after,
	.nav-link:hover:after {
		opacity:1;
	}


	.navbar-toggler {
		margin:10px auto;
		border-color: #dddddd;
	}

	.navbar-toggler-icon {
		background-image: $navbar-light-toggler-bg;
	}
}






.header-phone_number {
	position:relative;
	@extend %title-font;
	display:inline-block;
	margin:25px auto;
	font-size:1.05rem;
	height:35px;
	line-height:35px;

	&:before {
		content:'';
		display:inline-block;
		position:absolute;
		background:url(../images/phone.svg) no-repeat left center;
		background-size:contain;
		width:28px;
		height:35px;
		left:-10px;
		opacity:0.4;
	}
	
	

	&:after {
		content:'';
		display:block;
		width:40px;
		height:$default-border-width;
		background: $batoul-bleu-clair;
		position:absolute;
		right:0;
		bottom:-5px;
	}
}


// ====================================================
// Large screen
// ====================================================

@media (min-width: 1024px) {
	.header-phone_number {
		display:block;
		margin:0;
		position:absolute;
		right:0;
		bottom:0;
	}

	.image-heading__image {
		max-width:71%;
	}

	.image-heading--home {
		&:before, &:after { display:block; }

		.image-heading__overlay-content {
			font-size:1.8rem;
			color:#fff;
			text-align:right;
			position:absolute;
			top:50%;
			right:0;
			@include translate(0, -50%);
			z-index:5;
			text-shadow: 1px 0px 6px rgba(#000000, 0.5);
			margin:0;
			text-align:center;
		}
	}

	.navbar-batoul {
		margin:2% 0;
		margin-top:5%;
		font-size:15px;
	}

	.navbar-nav {
		width:100%;
	}

	.navbar-nav .nav-item {
		/*-webkit-box-flex: 1;
		-webkit-flex: 1 0 auto;
		-ms-flex: 1 0 auto;
		flex: 1 0 auto;*/
	}
}