.main h1 {
	@extend %title-font;
	text-transform:uppercase;
	color:$batoul-bleu-clair;
	font-weight:200;
	font-size:3.6em;
	margin-bottom:0.6em;
}

@media (max-width: 768px) {
	.main h1 {
		font-size:2em;
		margin:1em 0;
	}
}