// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$batoul-rose: #fcf2f1;
$batoul-bleu-canard: #2b1a80;
$batoul-bleu-nuit: #231f51;
$batoul-bleu-clair: #99aad7;

$default-border-width:3px;

//$brand-primary:         #27ae60;

$body-color: $batoul-bleu-nuit;