.image-footing {
	position:relative;
	margin-top:-15%;
	z-index:0;
	margin-bottom:40px;


	&__image {
		display:block;
		max-width:100%;
		height:auto;
	}

	&:nth-child(odd) .image-footing__image {
		margin-left:auto;
	}
}


footer {
	margin-top:25px;
	@extend %title-font;
	text-transform:uppercase;
	font-size:12px;
	text-align:center;
}