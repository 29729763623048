
body {
	font-family: Helvetica, Arial, sans-serif;
	padding:5%;
	font-size:14px;
	background-color:$batoul-bleu-nuit;
}

h1, h2, h3, h4, h5, h6 {
	@extend %title-font;
}
h2 {
	font-size:1.2rem;
	letter-spacing:0.2rem;
	margin-bottom:40px;
}

p {
	letter-spacing:0.02em;
	line-height:1.7em;
}

@media (min-width: 1024px) {
	body { padding:5% 8%; font-size:15px; }
	h2 { font-size:2rem; }
}


#background {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	//overflow:hidden;

	canvas {
		/*position:absolute;
		top:50%;
		left:50%;
		transform: translate(-50%,-50%);*/
	}
}

#map {
	padding-bottom:44.374%;
}

#main .scene-main {
	width:100%;
}

.main-site-container {
	margin:0 auto;
	padding:3% 5%;
	max-width:1400px;
	background:#ffffff;
	position:relative;
	overflow:hidden;
	//border:1px solid #f0f0f0;
}

#main .home.main {

		/*&:after {
			@include tache(bleu-eau , 0.3);
			right:-5%;
			bottom:-11%;
			z-index:-1;
		}*/
}


// Page transitions
#main .scene-main {
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
#main .scene-main--fadein {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
#main.is-exiting .scene-main {
	-webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}





////

/*

.strongify {
	overflow:hidden;
	cursor:pointer;
	position:relative;
	display:inline-block;
	font-weight:200;

	//
	span {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;

		-webkit-clip-path: polygon(0 0, 80% 0, 81% 100%, 0% 100%);
		clip-path: polygon(0 0, 80% 0, 81% 100%, 0% 100%);

		&::before {
			content: attr(data-letters);
			color: red;
			position: absolute;
			left: 0;
			width: 100%;
			transition: transform 0.5s;
			top: 0;
			font-weight:900;
			//transform: translate3d(0,100%,0);
		}

	}


}*/